$project-class: '.labs';
$wrapper: 1680;

@function clampByDis($min, $max) {
  $percent: ($max * 100) / $wrapper;
  @return clamp(#{$min}px, #{$percent}vw, #{$max}px);
}

body {
  overflow: hidden;
}

.chromeperfectpixel-overlay-container,
.chromeperfectpixel-overlay {
  width: 100%!important;
  height: auto!important;
}

*, *:before, *:after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  width: 100%;
  min-height: 100vh;
  background: #000000;
  color: white;
  font-size: 14px;
  font-family: "KyivTypeSans", sans-serif;
  overflow-x: hidden;
  line-height: clampByDis(24, 30);
  position: relative;
}

body {
  //&:before {
  //  content: '';
  //  display: block;
  //  width: 100%;
  //  height: 100%;
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  background: #6E36FF;
  //  opacity: 0.1;
  //}
}

*::placeholder {
  color: white;
  font-size: clampByDis(16, 20);
  font-family: "KyivTypeSans", sans-serif;
}

* {
  scrollbar-face-color: #000000;
  scrollbar-arrow-color: #000000;
  scrollbar-track-color: #000000;
  scrollbar-shadow-color: #000000;
  scrollbar-highlight-color: #000000;
  scrollbar-3dlight-color: #000000;
  scrollbar-darkshadow-Color: #000000;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #170D33;
    padding-right: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C64AFF;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #C64AFF;
  }
}

p {
  margin: 0;
  padding: 0;
  color: #928aa7;
  font-weight: 300;
  line-height: clampByDis(24, 30);
}

a {
  text-decoration: none;
  color: #ffffff;

  &.link-hover {
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      left: -15px;
      top: -15px;
      width: calc(100% + 30px);
      height: calc(100% + 30px);
      pointer-events: none;
      background: url("../images/hover-links.png") no-repeat center/contain;
      transition: opacity 0.25s ease;
      opacity: 0;
      z-index: -1;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
}

.hidden {
  overflow: hidden;
}

@keyframes modalView {
  from { opacity: 0 }
  to { opacity: 1 }
}

#{$project-class} {

  &-img-response {
    display: block;
    width: 100%;
  }

  &-wrapper {
    //max-width: #{$wrapper}px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    background: #000000;
    font-size: clampByDis(16, 20);
    line-height: clampByDis(24, 30);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    @media (min-width: 1920px){
      max-width: calc(100% - 240px);
    }
    @media (min-width: 1620px){
      max-width: calc(100% - 240px);
    }
    @media (max-width: 1300px){
      max-width: calc(100% - 96px)
    }
    @media screen and (max-width: 480px){
      max-width: 100%;
      padding: 0 24px;
    }

    &.fixed-header {
      height: 126px;
    }
  }

  &-btn {
    font-family: "KyivTypeSans", sans-serif;
    font-size: 20px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    background-color: transparent;

    @media screen and (max-width: 768px){
      font-size: 16px;
      line-height: 24px;
    }

    &-clear {
      color: #ffffff;
      width: 183px;
      height: 56px;
      background-image: url(../images/btn-clear.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        transition: opacity 0.25s ease;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../images/btn-clear-hover.svg);
        opacity: 0;
      }

      &:hover {
        color: #21193E;

        &:before {
          opacity: 1;
        }
      }
    }
    &-accent {
      width: 270px;
      height: 80px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      background-image: url(../images/btn-accent.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      @media (min-width: 1920px) {
        width: 330px;
        height: 100px;
        font-size: 30px;
        line-height: 40px;
      }
      @media screen and (max-width: 768px){
        width: 184px;
        height: 56px;
      }

      @media screen and (max-width: 480px){
        background-image: url(../images/btn-accent-mobile.svg)
      }

      div {
        text-align: center;
        color: #21193E;
      }
      &:hover:after {
        opacity: 1;
      }
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        transition: opacity 0.25s ease;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../images/btn-accent-hover.svg);
        opacity: 0;
      }

      &:before {
        content: '';
        width: calc(100% - 20px);
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 10px;
        box-shadow: -20px 30px 60px rgba(125, 210, 163, 0.1), 20px 30px 60px rgba(213, 249, 139, 0.1);
        border-radius: 70px 0 70px 0;

        @media screen and (max-width: 480px){
          width: calc(100% - 40px)!important;
          height: calc(100% - 8px);
          top: 4px;
          left: 20px!important;
        }
      }
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 60px;

    @media screen and (max-width: 480px){
      padding-top: 40px;
    }

    &__logo {
      @media (min-width: 1920px) {
        img{
          width: 320px;
        }
      }
      @media screen and (max-width: 580px){
        img {
          max-width: 160px;
        }
      }
    }

    &-wrapper.fixed {
      position: fixed;
      top: 16px;
      left: 16px;
      width: calc(100% - 32px);
      padding: 16px;
      z-index: 100;
      background-color: rgba(65,55,89, 0.8);
      border-radius: 20px;
      animation: modalView 0.5s ease;

      @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        background-color: #150e3008;
        backdrop-filter: blur(100px);
      }

      @media screen and (max-width: 375px){
        display: none;
      }

      #{$project-class}-header {
        padding-top: 0;
      }
    }

    &-fixed-fantom {
      &.show {
        height: 126px;

        @media screen and (max-width: 375px){
          height: 86px;
        }
      }
    }

    &__nav {
      display: flex;
      align-items: center;

      &-item {
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 60px;
        }

        @media screen and (max-width: 1200px){
          display: none;
        }
      }
    }

    #{$project-class}-btn {
      @media screen and (max-width: 768px){
        width: 130px;
        height: 40px;
        background-size: cover;
      }
    }
  }

  &-section {

    &-first {
      margin-bottom: clampByDis(60, 100);
      @media (min-width: 1920px) {
        height: calc(100vh - 147px - 210px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;
        padding-bottom: 50px;
      }
      @media screen and (max-width: 991px){
        margin-bottom: 10px;
      }
      @media screen and (max-width: 550px){
        margin-bottom: 0;
      }
      .decor-1 {
        position: absolute;
        width: 971px;
        height: 403px;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: -1;

        background: url('../images/decor/1.svg') no-repeat center/contain;
      }
      .decor-2 {
        position: absolute;
        width: 880px;
        height: 900px;
        right: -330px;
        top: 0;
        z-index: -1;
        background: url('../images/decor/2.png') no-repeat center/contain;

        @media screen and (max-width: 768px){
          top: 250px;
        }

        @media screen and (max-width: 480px){
          top: 100px;
          width: 640px;
          right: -270px;
          display: none;
        }
      }

      &__content {
        margin-top: clampByDis(10, 100);
        position: relative;
        @media (min-width: 1920px) {
          margin-top: 0;
          padding-left: 100px;
        }
        @media screen and (max-width: 991px){
          display: flex;
          flex-direction: column;
          margin-top: 8px;
        }

        &-title {
          margin-top: 40px;
          font-size: clampByDis(32, 60);
          max-width: 508px;
          line-height: clampByDis(40, 70);
          position: relative;
          z-index: 1;
          margin-bottom: 60px;
          @media (min-width: 1920px){
            font-weight: 500;
            font-size: 70px;
            line-height: 80px;
            max-width: 1000px;
          }
          @media (min-width: 1940px){
            font-weight: 500;
            font-size: 90px;
            line-height: 100px;
            max-width: 1100px;
          }
          @media screen and (max-width: 991px){
            font-size: 60px;
            line-height: 70px;
            max-width: 100%;
            margin-bottom: 40px;
          }
          @media screen and (max-width: 640px){
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 32px;
            margin-top: 24px;
          }
          img {
            height: clampByDis(32, 60);
          }

          &:before {
            content: '';
            width: clampByDis(295, 509);
            height: clampByDis(88, 160);
            display: block;
            position: absolute;
            bottom: -15px;
            left: -20px;
            z-index: -1;
            background: url("../images/s1-text.svg") no-repeat center/contain;
          }
        }

        &-desc {
          font-size: clampByDis(16, 25);
          margin-bottom: 60px;
        }

        &-video {
          position: absolute;
          left: 40%;
          top: -60px;
          z-index: -5;
          height: fit-content;
          display: flex;
          overflow: hidden;
          @media (min-width: 1920px) {
            transform: translateY(-50%);
            top: 65%;
          }
          .video-playsinline-player {
            display: flex;
          }
          video{
            height: intrinsic;
          }
          @media screen and (max-width: 991px){
            position: relative;
            left: 0;
            right: 0;
            top: 0;
          }

          @media screen and (max-width: 480px){
            width: 115%;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
          }

          //&:before {
          //  content: '';
          //  display: block;
          //  width: 100%;
          //  height: 100%;
          //  position: absolute;
          //  left: 0;
          //  top: 0;
          //  //background: #6E36FF;
          //  opacity: 0.1;
          //  z-index: 1;
          //}
        }
      }
    }
  }
}

.footer{
  margin-top: auto;
  padding-bottom: 60px;
  @media screen and (max-width: 991px){
    padding-bottom: 40px;
  }
  &-container{
    max-width: 1200px;
    margin: 0 auto;
    @media (min-width: 1920px) {
      max-width: calc(100% - 240px);
    }
    @media (min-width: 1620px){
      max-width: calc(100% - 240px);
    }
    @media (max-width: 1300px){
      max-width: calc(100% - 96px)
    }

    @media screen and (max-width: 480px){
      max-width: 100%;
      padding: 0 24px;
    }
  }
  &-logos{
    display: flex;
    margin-bottom: 30px;
    .item{
      background: #232026;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      @media (min-width: 1920px) {
        width: 80px;
        height: 80px;
        img{
          width: 53px;
        }
      }
      @media screen and (max-width: 550px){
        width: 48px;
        height: 48px;
        margin-right: 8px;
        img{
          width: 32px;
        }
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
  &-copyright{
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      color: #726F76;
      @media (min-width: 1920px) {
        font-size: 30px;
        line-height: 40px;
      }
      @media screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .mobile-links{
      display: none;
      @media screen and (max-width: 550px) {
        display: flex;
        a{
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-right: 24px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
    .links{
      display: flex;
      @media screen and (max-width: 550px) {
        display: none;
      }
      a{
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #FFFFFF;
        margin-right: 40px;
        transition: all 0.4s ease;
        @media (min-width: 1920px) {
          font-size: 30px;
          line-height: 40px;
        }
        &:last-child{
          margin-right: 0;
        }
        &:hover{
          opacity: 0.8;
        }
      }
    }
  }
}